import Button from 'aac-components/components/Button';
import {useForm, useWatch} from 'react-hook-form';
import Recaptcha from 'aac-components/components/Recaptcha';

import {useEffect, useRef, useState} from 'react';
import Textarea from 'aac-components/components/ReactHookForm/Textarea';
import styled from 'styled-components';

import {BREAKPOINT} from 'aac-components/utils/styles';
import CheckboxMultiSelectInput from 'aac-components/components/ReactHookForm/CheckboxMultiSelectInput';
import Disclaimer from 'aac-components/components/Disclaimer';
import Loading from 'aac-components/components/Svgs/loading';
import CallrailLink from '../CallrailLink';
import ResponseList from './ResponseList';
import Form from 'aac-components/components/ReactHookForm/Form';

const EmailInterestSurvey = () => {
    const [showForm, setShowForm] = useState(true);
    const [loading, setLoading] = useState(false);
    const [previousSubmit, setPreviousSubmit] = useState(false);
    const [userResponses, setUserResponses] = useState(null);

    const ref = useRef();

    const form = useForm();

    const prefersEmail =
        useWatch({
            control: form.control,
            name: 'prefer_email',
        }) === 'yes';
    const hasOtherEmailInterest = useWatch({
        control: form.control,
        name: 'email_interest_types',
    })?.includes('other');

    const handleSubmit = (formData) => {
        setLoading(true);
        setShowForm(false);
        setUserResponses(formData);

        ref.current.scrollIntoView({
            behavior: 'smooth',
        });

        // do this so loading icon shows
        const delay = setTimeout(() => {
            // do submit stuff
            const {
                email_interest_comments = '',
                email_interest_types = [],
                prefer_email = '',
                why_prefer_email = '',
                treatment_interest = '',
            } = formData;

            const heapData = {
                ['Prefers Email']: prefer_email,
                ['Prefers Email Comments']: why_prefer_email,
                ['Interest In Treatment']: treatment_interest,
                ['Other Email Interest Comments']: email_interest_comments,
                ['Email Interest: Process']: email_interest_types?.includes('process')
                    ? 'yes'
                    : 'no',
                ['Email Interest: Facilities']: email_interest_types?.includes(
                    'facilities',
                )
                    ? 'yes'
                    : 'no',
                ['Email Interest: General Info']: email_interest_types?.includes(
                    'general_info',
                )
                    ? 'yes'
                    : 'no',
                ['Email Interest: Other']: email_interest_types?.includes('other')
                    ? 'yes'
                    : 'no',
            };

            window?.heap &&
                window?.heap?.track('Email Interest Survey', {
                    ...heapData,
                });

            setLoading(false);
        }, 2000);

        sessionStorage.setItem('submit_email_survey', true);

        return () => clearTimeout(delay);
    };

    useEffect(() => {
        const submitted = sessionStorage.getItem('submit_email_survey');
        setPreviousSubmit(submitted);
    }, []);

    const showResponseScreen = (!loading && !showForm) || previousSubmit;

    return (
        <div className="email-survey" ref={ref}>
            <h3>Email Interest Survey</h3>

            <Form onSubmit={handleSubmit} id="email-interest" form={form}>
                <div
                    className={`email-survey__container ${
                        showForm && !previousSubmit ? 'show' : ''
                    }`}>
                    <ol>
                        <li>
                            <div className="email-survey__question">
                                Are you or a loved one interested in rehab treatment?
                                <sup>*</sup>
                            </div>
                            <div className="email-survey__radio-container">
                                <div>
                                    <label htmlFor="yes">Yes</label>
                                    <input
                                        type="radio"
                                        id="yes"
                                        value="yes"
                                        name="treatment_interest"
                                        {...form.register('treatment_interest', {
                                            required: true,
                                        })}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="no">No</label>
                                    <input
                                        type="radio"
                                        id="no"
                                        value="no"
                                        name="treatment_interest"
                                        {...form.register('treatment_interest', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>
                            {form?.formState?.errors?.['prefer_email'] && (
                                <div className="custom-error">
                                    Please select yes or no
                                </div>
                            )}
                        </li>
                        <li>
                            <div className="email-survey__question">
                                Would you prefer email communication over others like
                                calls or text?<sup>*</sup>
                            </div>
                            <div className="email-survey__radio-container">
                                <div>
                                    <label htmlFor="yes">Yes</label>
                                    <input
                                        type="radio"
                                        id="yes"
                                        value="yes"
                                        name="prefer_email"
                                        {...form.register('prefer_email', {
                                            required: true,
                                        })}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="no">No</label>
                                    <input
                                        type="radio"
                                        id="no"
                                        value="no"
                                        name="prefer_email"
                                        {...form.register('prefer_email', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>
                            {form?.formState?.errors?.['prefer_email'] && (
                                <div className="custom-error">
                                    Please select yes or no
                                </div>
                            )}
                            {prefersEmail && (
                                <TextareaStyles>
                                    <Textarea
                                        label="Why do you prefer email?"
                                        fieldName="why_prefer_email"
                                        placeholder="I prefer email because..."
                                        {...form}
                                    />
                                </TextareaStyles>
                            )}
                        </li>
                        <li>
                            <div>
                                <CheckboxMultiSelectInputStyles>
                                    <CheckboxMultiSelectInput
                                        fieldName="email_interest_types"
                                        label="What types of emails would you be interested in?"
                                        required={true}
                                        columns={1}
                                        {...form}
                                        options={[
                                            {
                                                label: 'Emails about the admissions process',
                                                value: 'process',
                                            },
                                            {
                                                label: 'Emails about our facilities',
                                                value: 'facilities',
                                            },
                                            {
                                                label: 'Emails with general information about addiction',
                                                value: 'general_info',
                                            },
                                            {
                                                label: 'Other',
                                                value: 'other',
                                            },
                                        ]}
                                    />
                                </CheckboxMultiSelectInputStyles>
                            </div>
                            {hasOtherEmailInterest && (
                                <TextareaStyles>
                                    <Textarea
                                        label="What other types of emails would you be interested in?"
                                        fieldName="email_interest_comments"
                                        maxLength="5"
                                        {...form}
                                    />
                                </TextareaStyles>
                            )}
                        </li>
                    </ol>
                    <Button
                        type="submit"
                        style={{
                            display: 'block',
                            margin: '0 auto 40px auto',
                        }}>
                        Submit Survey
                    </Button>
                    <div className="disclaimers">
                        <Disclaimer type="contact" />
                        <Recaptcha />
                    </div>
                </div>
            </Form>
            {loading && (
                <div className="email-survey__loading">
                    <Loading />
                </div>
            )}
            {showResponseScreen && (
                <div className="email-survey__response">
                    <div>
                        Thank you for taking our survey, your responses will help us
                        improve our online and email experience.{' '}
                    </div>
                    <div>
                        If you or a loved one is seeking addiction treatment, please call
                        us at: <CallrailLink />
                    </div>
                    {userResponses !== null && (
                        <ResponseList userResponses={userResponses} />
                    )}
                </div>
            )}
            <style jsx>
                {`
                    sup {
                        color: var(--error);
                        font-weight: normal;
                    }
                    .email-survey {
                        background: linear-gradient(
                            10deg,
                            rgba(146, 172, 211, 0.3) 0%,
                            rgba(229, 235, 245, 0.3) 100%
                        );
                        border: 2px solid var(--secondary-200);
                        box-shadow: var(--box-shadow);
                        margin: 40px auto 40px auto;
                        padding: 40px 15px;
                        border-radius: 8px;
                        scroll-margin: 200px;
                        max-width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .email-survey {
                            margin: 80px auto 80px auto;
                            max-width: 50%;
                            padding: 40px;
                        }
                    }
                    .email-survey h3 {
                        margin-bottom: 40px;
                        text-align: center;
                    }

                    .email-survey__container {
                        display: none;
                    }
                    .email-survey__container.show {
                        display: block;
                    }

                    .email-survey ol li {
                        text-align: left;
                        margin-bottom: 40px;
                    }
                    .email-survey__question {
                        font-weight: bold;
                        color: var(--primary-400);
                        margin-bottom: 8px;
                    }
                    .email-survey__radio-container {
                        display: flex;
                        text-align: center;
                        justify-content: ;
                    }
                    .email-survey__radio-container > div:first-child {
                        margin-right: 24px;
                    }
                    label {
                        text-align: center;
                    }
                    input {
                        margin: 0 auto;
                        cursor: pointer;
                    }
                    .email-survey__response > div {
                        margin-bottom: 16px;
                        line-height: 24px;
                        font-size: 16px;
                    }
                    .custom-error {
                        margin-top: 8px;
                        color: var(--error);
                        font-weight: bold;
                        font-size: 12px;
                    }
                `}
            </style>
        </div>
    );
};

export default EmailInterestSurvey;

const CheckboxMultiSelectInputStyles = styled.div`
    .label-container {
        color: var(--primary-400);
    }
    .checkbox-multi__options {
        grid-gap: 24px;
    }
    .checkbox-multi__option {
        display: grid;
        grid-template-columns: 20px auto;
        grid-gap: 16px;
    }
    input {
        cursor: pointer;
    }
`;
const TextareaStyles = styled.div`
    textarea {
        max-height: 100px;
        min-height: 100px;
    }
    .label-container {
        margin-top: 24px;
    }
`;
const LinkStyles = styled.div`
    a {
        color: var(--interactive-300);
        text-decoration: none;
        color: var(--secondary-400);
    }
`;
