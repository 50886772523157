import CallrailLink from '../CallrailLink';

const ResponseList = ({userResponses}) => {
    const {
        treatment_interest = null,
        prefer_email = null,
        email_interest_types = [],
    } = userResponses;

    return (
        <div className="response-list">
            <div className="response-list__title">
                Based on your responses, you may be interested in:
            </div>
            {treatment_interest === 'yes' && (
                <div className="response-list__item">
                    <div className="response-list__item--title">
                        Next Steps Towards Treatment
                    </div>
                    <ul>
                        <li onClick={(e) => window?.AAC?.openSlideInSsvob(e)}>
                            Check Insurance Coverage
                        </li>
                        <li>
                            <a href="/treatment-centers">Find A Treatment Center</a>
                        </li>
                        <li>
                            <a href="/payment-options">Payment Options</a>
                        </li>
                    </ul>
                </div>
            )}
            {treatment_interest === 'no' && (
                <div className="response-list__item">
                    <div className="response-list__item--title">
                        Learning More About Addiction
                    </div>
                    <ul>
                        <li>
                            <a href="/self-assessment">Substance Abuse Assessment</a>
                        </li>
                        <li>
                            <a href="/adult-addiction-treatment-programs/signs">
                                Signs of Addiction
                            </a>
                        </li>
                        <li>
                            <a href="/guides">
                                Rehab Guides for Addiction & Mental Health
                            </a>
                        </li>
                        <li>
                            <a href="/disease-of-addiction">Disease of Addiction</a>
                        </li>
                    </ul>
                </div>
            )}
            {prefer_email === 'no' && (
                <div className="response-list__item">
                    <div className="response-list__item--title">
                        Other Ways to Communicate With Us
                    </div>
                    <ul>
                        <li onClick={() => window?.AAC?.openSlideInSms()}>
                            Text Support
                        </li>
                        <li>
                            <div>
                                <CallrailLink
                                    style={{
                                        color: 'var(--secondary-300)',
                                        textDecoration: 'none',
                                    }}>
                                    Call us 24/7
                                </CallrailLink>
                            </div>
                        </li>
                    </ul>
                </div>
            )}
            {Array.isArray(email_interest_types) && email_interest_types?.length >= 1 && (
                <div className="response-list__item">
                    <div className="response-list__item--title">
                        Exploring More Information
                    </div>
                    <ul>
                        {email_interest_types?.includes('process') && (
                            <li>
                                <a href="/admissions">Our Admissions Process</a>
                            </li>
                        )}
                        {email_interest_types?.includes('facilities') && (
                            <li>
                                <a href="/treatment-centers">Our Facilities</a>
                            </li>
                        )}
                        {email_interest_types?.includes('general_info') && (
                            <li>
                                <a href="/rehab-guide">Rehab Guide</a>
                            </li>
                        )}
                        <li>
                            <a href="/insurance-coverage">
                                Insurane Provider Information
                            </a>
                        </li>
                    </ul>
                </div>
            )}
            <style jsx>
                {`
                    .response-list {
                        border-top: 1px solid var(--gray-300);
                        padding-top: 24px;
                    }
                    .response-list__title {
                        margin-bottom: 24px;
                        color: var(--primary-300);
                        font-weight: bold;
                    }
                    .response-list__item {
                        margin-bottom: 14px;
                    }
                    .response-list__item--title {
                        margin-bottom: 16px;
                    }
                    ul {
                        list-style: none;
                        padding: 0;
                    }
                    li {
                        color: var(--secondary-300);
                        margin-bottom: 8px;
                        list-style: none;
                        display: grid;
                        grid-template-columns: 6px auto;
                        grid-gap: 8px;
                        align-items: center;
                        margin-left: 24px;
                        cursor: pointer;
                        max-width: fit-content;
                    }
                    li::before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        background: var(--secondary-300);
                        border-radius: 100%;
                        display: block;
                    }
                    a {
                        color: var(--secondary-300);
                        text-decoration: none;
                        display: block;
                        max-width: fit-content;
                    }
                `}
            </style>
        </div>
    );
};
export default ResponseList;
