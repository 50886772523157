import {BREAKPOINT} from '../../lib/styles';
import {shortcodes} from '../../lib/shortcodes';
import Layout from '../Layout';
import Image from 'next/image';
import PageContent from '../PageContent';
import {PageTags} from '../ContentTags';
import Breadcrumbs from 'aac-components/components/Breadcrumbs';
import styled from 'styled-components';
import Byline from '../Byline';
import EmailInterestSurvey from '../EmailInterestSurvey';
import {useContext} from 'react';
import AppContext from '../AppContext';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';
import PagePills from '../PagePills';

const FullWidthPageTemplate = (props) => {
    const {isMobile} = useContext(AppContext);

    const {
        data: {
            id = 0,
            slug = '',
            modified = '',
            byline_info = [],
            content: {rendered: content = ''} = {},
            title: {rendered: title = ''} = {},
            featured_image = [],
            tags = [],
            acf: {hero_description = '', show_byline = false} = {},
        } = {},
    } = props;
    const backgroundImage = featured_image?.[4] || '';

    const isEmailSurveyPage = slug === 'email';

    return (
        <Layout>
            <div className="hero">
                {backgroundImage && (
                    <Image
                        src={backgroundImage}
                        fill
                        style={{
                            objectFit: 'cover',
                        }}
                        sizes="100vw"
                        priority={true}
                        alt="American Addiction Centers"
                    />
                )}
                <div className="hero__inner">
                    <BreadcrumbsStyles>
                        <Breadcrumbs />
                    </BreadcrumbsStyles>
                    <div className="hero__inner--content">
                        <h1 className="title" dangerouslySetInnerHTML={{__html: title}} />
                        <div
                            className="hero-desc"
                            dangerouslySetInnerHTML={{__html: hero_description}}
                        />
                        <PagePillsStyles>
                            <PagePills />
                        </PagePillsStyles>
                    </div>
                </div>
            </div>

            <div className="container">
                {isEmailSurveyPage && <EmailInterestSurvey />}
                <PageContent showToc={isEmailSurveyPage ? false : true} />
                <PageTags tags={tags} style={{textAlign: 'center'}} />
                {show_byline && (
                    <Byline contributors={byline_info} lastModified={modified} />
                )}
            </div>
            <style jsx>{shortcodes}</style>

            <style jsx>
                {`
                    h1 {
                        text-align: center;
                        margin-bottom: 40px;
                    }
                    .container {
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                        padding: 0 15px;
                    }

                    .title-with-button {
                        display: flex;
                        align-items: center;
                        padding: 30px 15px;
                        justify-content: space-between;
                    }

                    .hero {
                        position: relative;
                        margin-bottom: 40px;
                        padding: 0 15px;
                        background: rgba(255, 255, 255, 0.3);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero {
                            margin-bottom: 40px;
                            padding: 0;
                        }
                    }
                    .hero__inner {
                        width: 100%;
                        position: relative;
                        text-align: center;
                        background: linear-gradient(
                            90deg,
                            rgba(255, 255, 255, 0.5) 0.02%,
                            rgba(255, 255, 255, 0.3) 99.96%
                        );
                    }
                    .hero__inner--content {
                        padding: 40px 15px;
                        max-width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__inner--content {
                            padding: 120px 15px;
                            max-width: 75%;
                            margin: 0 auto;
                        }
                    }

                    .hero-desc {
                        font-size: 16px;
                        line-height: 25px;
                        margin: 0 auto;
                        max-width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero-desc {
                            max-width: 75%;
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }

                    .email__content {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .email__content {
                            grid-template-columns: 1fr 1fr;
                        }
                    }
                `}
            </style>
        </Layout>
    );
};
export default FullWidthPageTemplate;

const BreadcrumbsStyles = styled.div`
    .breadcrumbs {
        margin: 0 auto;
        max-width: ${MAX_WIDTH_PX};
        padding-top: 24px;
    }
`;
const PagePillsStyles = styled.div`
    .pills__container {
        justify-content: center;
        margin: 40px auto;
        max-width: fit-content;
    }
`;
