import TableOfContentsModified from '../TableOfContents';
import {BREAKPOINT} from '../../lib/styles';
import {useContext} from 'react';
import AppContext from '../AppContext';

const PageContent = (props) => {
    const {showToc = true} = props;

    const {pageProps, isMobile} = useContext(AppContext);
    const {data: {template = '', content: {rendered: content = ''} = {}} = {}} =
        pageProps;

    return (
        <div id="wordpress-content">
            <div>
                {showToc && (
                    <div className="toc__container">
                        <TableOfContentsModified />
                    </div>
                )}
                <div dangerouslySetInnerHTML={{__html: content}} />
            </div>
            <style jsx>
                {`
                    #wordpress-content {
                        box-sizing: border-box;
                        max-width: 100%;
                        position: relative;
                        overflow-x: hidden;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        #wordpress-content {
                            padding: 0px 0 60px 0;
                        }
                    }
                    .toc__container {
                        max-width: ${template === 'full-width.php' && !isMobile
                            ? '33%'
                            : '100%'};
                        margin: 0 auto;
                        min-height: none;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .toc__container {
                            min-height: 88px;
                        }
                    }

                    :global(#wordpress-content h2) {
                        margin: 30px 0 24px 0;
                    }
                    :global(#wordpress-content ol) {
                        margin-left: 20px;
                    }
                `}
            </style>
        </div>
    );
};
export default PageContent;
