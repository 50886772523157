import Link from 'next/link';
import {useState} from 'react';
import {getElemOffset} from '../../lib/utils';
import React from 'react';

export const Tag = (props) => {
    const {
        tag: {name, slug},
    } = props;

    return (
        <React.Fragment>
            {slug && (
                <Link href={`/tag?tag=${slug}`} as={`/tag?tag=${slug}`} legacyBehavior>
                    <a>
                        <div
                            className="content-tag"
                            dangerouslySetInnerHTML={{__html: name}}
                        />
                    </a>
                </Link>
            )}

            <style jsx>
                {`
                    a {
                        line-height: normal;
                        text-decoration: none;
                    }
                    .content-tag {
                        text-transform: uppercase;
                        background: var(--interactive-100);
                        color: var(--interactive);
                        padding: 5px 10px;
                        font-size: 14px;
                        border-radius: 5px;
                        font-weight: 600;
                        cursor: pointer;
                        text-align: center;
                        width: fit-content;
                        max-height: 30px;
                        margin: 0 5px 5px 0;
                    }
                    .content-tag:hover {
                        box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.1);
                        transition: all 0.25s;
                    }
                `}
            </style>
        </React.Fragment>
    );
};

export const PageTags = (props) => {
    const {tags = []} = props;
    if (!Array.isArray(tags) || tags.length < 1) return null;
    return (
        <div className="page-tags" {...props}>
            <div className="header text-2xl bold">Related Tags</div>
            <TagCluster tags={tags} />
            <style jsx>
                {`
                    .page-tags {
                        width: 100%;
                        max-width: 600px;
                        background: #fff;
                        margin-bottom: 24px;
                    }
                    .header {
                        margin-bottom: 16px;
                    }
                `}
            </style>
        </div>
    );
};

export const TagCluster = ({tags = []}) => {
    const [showAll, toggleShowAll] = useState(false);

    const scrollToClusterTop = (e) => {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            const element = e.target;
            const closest = element.closest('.tag-cluster');

            if (!element || !closest) return;
            const elOffset = getElemOffset(closest);

            elOffset.top &&
                window.scrollTo({
                    top: elOffset.top - 250,
                    behavior: 'smooth',
                });
        }
    };

    return (
        <div className="tag-cluster">
            <div className="container">
                {tags.map((tag, index) => {
                    if (index > 15 && !showAll) return null;
                    return <Tag tag={{...tag}} key={`${tag?.slug}-${index}`} />;
                })}
            </div>
            {tags.length > 15 && (
                <React.Fragment>
                    {showAll ? (
                        <div
                            className="show-more"
                            onClick={(e) => {
                                toggleShowAll((prevState) => !prevState),
                                    scrollToClusterTop(e);
                            }}>
                            Show Less
                        </div>
                    ) : (
                        <div
                            className="show-more"
                            onClick={() => toggleShowAll((prevState) => !prevState)}>
                            Show More
                        </div>
                    )}
                </React.Fragment>
            )}
            <style jsx>
                {`
                    .container {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .show-more {
                        margin-top: 15px;
                        font-size: 16px;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                `}
            </style>
        </div>
    );
};
